import { Link } from "react-router-dom";
import { UserPlus } from "lucide-react";
import PropTypes from "prop-types";

function AddContactButton({ isCompact = false }) {
  const baseClasses =
    "flex items-center justify-center transition-all duration-200 bg-gradient-to-r from-orange-400 to-orange-600 text-white hover:from-orange-500 hover:to-orange-700 rounded-full";
  const compactClasses = "px-4 py-2 text-sm font-medium";
  const fullClasses = "py-3 px-6 text-lg font-semibold shadow-lg";

  return (
    <Link
      to="/create-contact"
      className={`${baseClasses} ${isCompact ? compactClasses : fullClasses}`}
    >
      <UserPlus
        size={isCompact ? 18 : 24}
        className={isCompact ? "mr-1" : "mr-2"}
      />
      <span>{isCompact ? "Add Contact" : "Add New Contact"}</span>
    </Link>
  );
}

AddContactButton.propTypes = {
  isCompact: PropTypes.bool,
};

export default AddContactButton;
