import { Target, Users, Bot } from "lucide-react";
import jeff from "../assets/images/touchedup.jpeg";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const AboutPage = () => {
  return (
    <motion.div
      className="bg-gray-100 dark:bg-gray-900 min-h-screen"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container mx-auto px-4 py-8">
        <motion.header className="text-center mb-12" variants={itemVariants}>
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">
            About rēlii
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Your AI-powered PRM solution for nurturing personal connections
          </p>
        </motion.header>

        <main className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Target className="text-blue-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Our Mission
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              At rēlii, our mission is to{" "}
              <strong className="text-blue-600 dark:text-blue-400">
                revolutionize personal relationship management
              </strong>{" "}
              by combining cutting-edge AI with intuitive design. We strive to
              make managing and nurturing your personal connections as
              effortless as possible. Our app simplifies contact management
              through{" "}
              <strong className="text-blue-600 dark:text-blue-400">
                AI-powered tools
              </strong>{" "}
              that allow users to input information via text, voice, or photos,
              while seamlessly utilizing your existing contact lists and tools.
            </p>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed mt-4">
              This platform is designed for those who{" "}
              <strong className="text-blue-600 dark:text-blue-400">
                truly care about their network
              </strong>{" "}
              and are committed to actively engaging with it. We'll provide you
              with the tools and data you need, but you still need to do the
              legwork. The more you invest in using the platform, the more value
              you will gain. Our app enhances communication by offering{" "}
              <strong className="text-blue-600 dark:text-blue-400">
                intelligent summaries of contacts and relevant updates
              </strong>{" "}
              from their world of interests that matter to them.
            </p>
          </motion.section>

          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Bot className="text-green-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                How We Operate
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              We believe that building meaningful relationships requires more
              than just technology—it requires a{" "}
              <strong className="text-green-600 dark:text-green-400">
                human touch
              </strong>
              . That's why we're committed to{" "}
              <strong className="text-green-600 dark:text-green-400">
                iterative improvements
              </strong>
              , guided by the invaluable feedback from our users. Our team
              focuses on{" "}
              <strong className="text-green-600 dark:text-green-400">
                high-quality outputs
              </strong>
              , empowering each member to work autonomously while delivering
              value every day.
            </p>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed mt-4">
              While our long-term vision is still evolving, we are inspired by
              the possibility of{" "}
              <strong className="text-green-600 dark:text-green-400">
                growing globally, supporting multiple cultures and languages
              </strong>
              . We invite you to join us on this journey and shape the future of
              personal relationship management together.
            </p>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed mt-4">
              <strong className="text-green-600 dark:text-green-400">
                Data privacy and security
              </strong>{" "}
              are our top priorities. We ensure that your information is safe,
              secure, and fully compliant with the highest standards.
            </p>
          </motion.section>

          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md md:col-span-2"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Users className="text-purple-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Meet the Founding Team
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start">
              <div className="md:w-1/4 lg:w-1/5 flex justify-center mb-4 md:mb-0 md:mr-6">
                <div className="bg-gray-300 dark:bg-gray-600 rounded-full overflow-hidden w-52 h-52">
                  <img
                    src={jeff}
                    alt="Jeff Rogers"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="md:w-3/4 lg:w-4/5">
                <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
                  Jeff Rogers - Founder
                </h3>
                <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
                  Jeff Rogers is a{" "}
                  <strong className="text-purple-600 dark:text-purple-400">
                    seasoned engineering leader and entrepreneur
                  </strong>{" "}
                  with a passion for technology and innovation. With over{" "}
                  <strong className="text-purple-600 dark:text-purple-400">
                    two decades of experience
                  </strong>{" "}
                  in software engineering and product development, Jeff has a
                  proven track record of driving operational excellence and
                  strategic technical direction across various industries.
                </p>
                <p className="text-gray-600 dark:text-gray-400 leading-relaxed mt-4">
                  As the founder of rēlii, Jeff brings his expertise in{" "}
                  <strong className="text-purple-600 dark:text-purple-400">
                    agile methodologies, continuous integration and delivery,
                    and fostering cultures of stakeholder collaboration
                  </strong>{" "}
                  to create a platform that truly understands and enhances
                  personal relationships. His vision is to leverage AI
                  technology to help people build and maintain stronger, more
                  meaningful connections in both their personal and professional
                  lives.
                </p>
              </div>
            </div>
          </motion.section>
        </main>
      </div>
    </motion.div>
  );
};

export default AboutPage;
