import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Pricing = () => {
  return (
    <motion.div
      className="bg-gray-100 dark:bg-gray-900 min-h-screen"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container mx-auto px-4 py-8">
        <motion.header className="text-center mb-12" variants={itemVariants}>
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">
            Choose the Right Plan for You
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Flexible pricing options to meet the needs of individuals, growing
            teams, and enterprises.
          </p>
        </motion.header>

        <main>
          <motion.section
            className="grid grid-cols-1 md:grid-cols-3 gap-8"
            variants={itemVariants}
          >
            {/* Free Tier */}
            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex flex-col"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Free
              </h2>
              <p className="text-4xl font-bold text-gray-800 dark:text-gray-200 text-center mb-4">
                $0<span className="text-xl">/month</span>
              </p>
              <ul className="text-gray-600 dark:text-gray-400 leading-relaxed mb-4 flex-grow">
                <li className="mb-2">Up to 5 contacts</li>
                <li className="mb-2">Gmail integration only</li>
                <li className="mb-2">Basic AI-driven recommendations</li>
                <li className="mb-2">Limited integrations</li>
                <li className="mb-2">Basic analytics and reporting</li>
                <li className="mb-2">Community support</li>
                <li className="mb-2">Pay-Per-Message: $0.10/message</li>
              </ul>
              <Link
                to="/auth"
                className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 text-center"
              >
                Get Started
              </Link>
            </motion.div>

            {/* Premier Tier */}
            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md border-2 border-blue-600 flex flex-col"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Premier
              </h2>
              <p className="text-4xl font-bold text-gray-800 dark:text-gray-200 text-center mb-4">
                $10<span className="text-xl">/month</span>
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 text-center mb-4">
                (Or $100/year - Save 2 months)
              </p>
              <ul className="text-gray-600 dark:text-gray-400 leading-relaxed mb-4 flex-grow">
                <li className="mb-2">Up to 1,000 contacts</li>
                <li className="mb-2">Advanced AI-driven recommendations</li>
                <li className="mb-2">
                  Full integrations (CRM, Calendar, Slack)
                </li>
                <li className="mb-2">Enhanced analytics and reporting</li>
                <li className="mb-2">Customizable reminders and follow-ups</li>
                <li className="mb-2">Email support (24-hour response)</li>
                <li className="mb-2">Pay-Per-Message: $0.08/message</li>
              </ul>
              <Link
                to="/signup"
                className="bg-gray-400 text-white py-2 px-4 rounded-lg cursor-not-allowed opacity-50 text-center"
              >
                Get Premier
              </Link>
            </motion.div>

            {/* Enterprise Tier */}
            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex flex-col"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Enterprise
              </h2>
              <p className="text-4xl font-bold text-gray-800 dark:text-gray-200 text-center mb-4">
                $49<span className="text-xl">/month</span>
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 text-center mb-4">
                (Or $500/year - Save over 15%)
              </p>
              <ul className="text-gray-600 dark:text-gray-400 leading-relaxed mb-4 flex-grow">
                <li className="mb-2">Unlimited contacts</li>
                <li className="mb-2">Enterprise-level AI-driven insights</li>
                <li className="mb-2">
                  Advanced integrations (ERP, HR systems)
                </li>
                <li className="mb-2">
                  Comprehensive analytics & custom dashboards
                </li>
                <li className="mb-2">Dedicated account manager</li>
                <li className="mb-2">Priority support (2-hour response)</li>
                <li className="mb-2">Custom SLA</li>
                <li className="mb-2">White-glove onboarding & training</li>
                <li className="mb-2">Pay-Per-Message: $0.05/message</li>
              </ul>
              <Link
                to="/contact"
                className="bg-gray-400 text-white py-2 px-4 rounded-lg cursor-not-allowed opacity-50 text-center"
              >
                Contact Sales
              </Link>
            </motion.div>
          </motion.section>
        </main>
      </div>
    </motion.div>
  );
};

export default Pricing;
