// src/components/GoogleAuthChecker.jsx
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { CHECK_GOOGLE_AUTH_STATUS } from "../graphql/queries";

function GoogleAuthChecker({ user, setIsGoogleAuthenticated }) {
  const { data, loading } = useQuery(CHECK_GOOGLE_AUTH_STATUS, {
    skip: !user,
    variables: { email: user?.getUsername() },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      setIsGoogleAuthenticated(data.checkGoogleAuthStatus);
    }
  }, [data, loading, setIsGoogleAuthenticated]);

  return null; // This component doesn't render anything
}

export default GoogleAuthChecker;
