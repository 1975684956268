import React, { useState, useMemo, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import LoadingSpinner from "./LoadingSpinner";
import EmailSuccessModal from "./EmailSuccessModal";
import { getContact } from "../graphql/queries";
import { sendEmail } from "../graphql/mutations";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Cake,
  GraduationCap,
  Handshake,
  Send,
  EyeOff,
  Gift,
  Trash2,
  Save,
  X,
} from "lucide-react";

const LIST_DRAFT_EMAILS = gql`
  query ListDraftEmails {
    listDraftEmails {
      contactId
      touchpointEvent
      touchpointDate
      draftEmail
      createdAt
      status
    }
  }
`;

const LIST_CONTACTS = gql`
  query ListContacts {
    listContacts {
      id
      firstName
      lastName
      email
      phone
      contactSummary
      owner
    }
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const getEventIcon = (event) => {
  switch (event.toLowerCase()) {
    case "birthday":
      return <Cake className="text-orange-500" />;
    case "graduation":
      return <GraduationCap className="text-purple-500" />;
    case "anniversary":
      return <Gift className="text-pink-500" />;
    case "meeting":
      return <Handshake className="text-blue-500" />;
    default:
      return <Handshake className="text-black" />;
  }
};

function Touchpoints() {
  const {
    loading: contactsLoading,
    error: contactsError,
    data: contactsData,
  } = useQuery(LIST_CONTACTS);

  const {
    loading: emailsLoading,
    error: emailsError,
    data: emailsData,
  } = useQuery(LIST_DRAFT_EMAILS);

  const [expandedEmail, setExpandedEmail] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(null);
  const [sendEmailMutation] = useMutation(sendEmail);
  const [editingEmail, setEditingEmail] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [isEmailSuccessModalOpen, setIsEmailSuccessModalOpen] = useState(false);

  const draftEmails = useMemo(
    () => emailsData?.listDraftEmails || [],
    [emailsData]
  );

  const contactIds = useMemo(
    () => [...new Set(draftEmails.map((email) => email.contactId))],
    [draftEmails]
  );

  const contactMap = useMemo(() => {
    return (contactsData?.listContacts || []).reduce((map, contact) => {
      map[contact.id] = contact;
      return map;
    }, {});
  }, [contactsData]);

  const [contact, setContact] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState(null);

  const {
    data: contactData,
    loading: contactLoading,
    error: contactError,
  } = useQuery(getContact, {
    variables: { id: selectedContactId },
    skip: !selectedContactId,
  });

  useEffect(() => {
    if (contactIds.length > 0) {
      setSelectedContactId(contactIds[0]);
    }
  }, [contactIds]);

  useEffect(() => {
    if (contactData && !contactLoading && !contactError) {
      setContact(contactData.getContact);
    }
  }, [contactData, contactLoading, contactError]);

  if (contactsLoading || emailsLoading) return <LoadingSpinner />;

  if (contactsError && contactsError.message.includes("Not Authorized")) {
    return (
      <div className="text-center p-4">
        <p className="text-xl mb-4">You don't have any contacts yet.</p>
        <Link
          to="/add-contact"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add Your First Contact
        </Link>
      </div>
    );
  }

  if (contactsError || emailsError) {
    return (
      <p className="text-red-500">
        Error: {contactsError?.message || emailsError?.message}
      </p>
    );
  }

  const contacts = contactsData?.listContacts || [];
  if (contacts.length === 0) {
    return (
      <div className="text-center p-4">
        <p className="text-xl mb-4">You don't have any contacts yet.</p>
        <Link
          to="/create-contact"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add Your First Contact
        </Link>
      </div>
    );
  }

  if (draftEmails.length === 0) {
    return (
      <div className="text-center p-4">
        <p className="text-xl mb-4">You don't have any draft emails yet.</p>
        <p>
          Draft emails will appear here once they're generated for your
          contacts.
        </p>
      </div>
    );
  }

  const truncateMessage = (message, maxLength = 50) => {
    return message.length > maxLength
      ? `${message.substring(0, maxLength)}...`
      : message;
  };

  const handleSendEmail = async (email) => {
    setSendingEmail(email.contactId);
    try {
      const { data } = await sendEmailMutation({
        variables: {
          to: contact?.email || "",
          subject: email.touchpointEvent,
          body: email.draftEmail,
        },
      });
      if (data.sendEmail === "Email sent successfully") {
        setIsEmailSuccessModalOpen(true);
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      let errorMessage = "Failed to send email. ";
      if (error.message.includes("Refresh token not found")) {
        errorMessage += "Please reconnect your Google account.";
      } else {
        errorMessage += "Please try again later.";
      }
      alert(errorMessage);
    } finally {
      setSendingEmail(null);
    }
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 dark:text-white text-center">
        Draft Emails
      </h2>
      <motion.div
        className="space-y-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {draftEmails.map((email) => {
          const contactInfo = contactMap[email.contactId] || {};
          const isExpanded = expandedEmail === email.draftEmail;

          return (
            <motion.div
              key={`${email.contactId}-${email.touchpointEvent}`}
              variants={itemVariants}
              className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
            >
              <div className="grid grid-cols-3 gap-4 items-start">
                <div className="flex items-center space-x-2">
                  <span className="text-2xl">
                    {getEventIcon(email.touchpointEvent)}
                  </span>
                  <div>
                    <Link
                      to={`/contact/${email.contactId}`}
                      className="font-semibold text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200"
                    >
                      {contactInfo.fullName ||
                        `${contactInfo.firstName} ${contactInfo.lastName}` ||
                        email.contactId}
                    </Link>
                    <br />
                    <a
                      href={`mailto:${contactInfo.email}`}
                      className="text-sm text-blue-500 hover:text-blue-700"
                    >
                      {contactInfo.email || "N/A"}
                    </a>
                  </div>
                </div>

                <div className="text-gray-600 dark:text-gray-400">
                  {isExpanded ? (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {editingEmail === email.draftEmail ? (
                        <div>
                          <textarea
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                            className="w-full p-2 border rounded"
                            rows="5"
                          />
                          <div className="mt-2 flex justify-end space-x-2">
                            <button
                              onClick={() => {
                                // Implement save logic here
                                setEditingEmail(null);
                              }}
                              className="p-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                            >
                              <Save size={18} />
                            </button>
                            <button
                              onClick={() => {
                                setEditingEmail(null);
                                setEditedContent("");
                              }}
                              className="p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                            >
                              <X size={18} />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {email.draftEmail}
                          <button
                            onClick={() => {
                              setEditingEmail(email.draftEmail);
                              setEditedContent(email.draftEmail);
                            }}
                            className="ml-2 text-blue-500 hover:text-blue-700 underline cursor-pointer bg-transparent border-none p-0"
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </motion.div>
                  ) : (
                    truncateMessage(email.draftEmail)
                  )}
                  <button
                    onClick={() =>
                      setExpandedEmail(isExpanded ? null : email.draftEmail)
                    }
                    className="mt-2 text-blue-500 hover:text-blue-700"
                  >
                    {isExpanded ? "less" : "more"}
                  </button>
                </div>

                <div className="flex flex-col items-end">
                  <span
                    className={`font-semibold mb-2 ${
                      email.status === "PENDING"
                        ? "text-yellow-500"
                        : "text-green-500"
                    }`}
                  >
                    {email.status}
                  </span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleSendEmail(email)}
                      disabled={sendingEmail === email.contactId}
                      className="p-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 transition-colors group relative"
                    >
                      <Send size={18} />
                      <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                        Send
                      </span>
                    </button>
                    <button className="p-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors group relative">
                      <EyeOff size={18} />
                      <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                        Hide
                      </span>
                    </button>
                    <button className="p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors group relative">
                      <Trash2 size={18} />
                      <span className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                        Delete
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          );
        })}
      </motion.div>
      <EmailSuccessModal
        isOpen={isEmailSuccessModalOpen}
        onClose={() => setIsEmailSuccessModalOpen(false)}
      />
    </div>
  );
}

export default Touchpoints;
