import React, { useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { CHECK_GOOGLE_AUTH_STATUS } from "../graphql/queries";

function SignIn({ userPool, setUser, setIsGoogleAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [checkGoogleAuthStatus] = useLazyQuery(CHECK_GOOGLE_AUTH_STATUS);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        setUser(cognitoUser);

        try {
          const { data } = await checkGoogleAuthStatus({
            variables: { email },
          });
          if (data && data.checkGoogleAuthStatus) {
            setIsGoogleAuthenticated(true);
            navigate("/dashboard");
          } else {
            navigate("/google-auth");
          }
        } catch (err) {
          console.error("Error checking Google auth status:", err);
          navigate("/google-auth");
        }
      },
      onFailure: (err) => {
        console.error("Error signing in:", err);
        handleSignInError(err);
      },
    });
  };

  const handleSignInError = (err) => {
    switch (err.code) {
      case "UserNotFoundException":
        setError("No account found with this email address.");
        break;
      case "NotAuthorizedException":
        setError("Incorrect email or password.");
        break;
      case "UserNotConfirmedException":
        setError("Please confirm your account before signing in.");
        break;
      case "PasswordResetRequiredException":
        setError("You need to reset your password. Please check your email.");
        break;
      case "InvalidParameterException":
        setError("Please enter a valid email address and password.");
        break;
      case "LimitExceededException":
        setError("Too many failed attempts. Please try again later.");
        break;
      default:
        setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      {error && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded text-center">
          {error}
        </div>
      )}
      <form onSubmit={handleSignIn}>
        <div className="mb-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-white leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
        <div className="mb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-white mb-3 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
    </>
  );
}

export default SignIn;
