import PropTypes from "prop-types";

const EmailSuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-gray-800">
          Email Sent Successfully
        </h2>
        <p className="text-gray-700">Your email has been sent successfully.</p>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

EmailSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailSuccessModal;
