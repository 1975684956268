import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-200 dark:bg-gray-800 py-8 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center items-center space-x-6">
          <Link
            to="/about"
            className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
          >
            About
          </Link>
          <Link
            to="/legal"
            className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
          >
            Legal
          </Link>
          <Link
            to="/pricing"
            className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
          >
            Pricing
          </Link>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScPuxv4wX-bmthHPFkrKxz3xw_92cP3nSoBG_NV6v9KDc5bhA/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-full transition-all duration-200"
          >
            Give Feedback
          </a>
        </nav>
        <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
          &copy; 2024 Relii. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
