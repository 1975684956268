import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { motion, AnimatePresence } from "framer-motion";

function AuthTabs({ userPool, setUser, setIsGoogleAuthenticated }) {
  const [activeTab, setActiveTab] = useState("signin");

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden w-full max-w-md">
        <div className="flex mb-4">
          <TabButton
            active={activeTab === "signin"}
            onClick={() => setActiveTab("signin")}
          >
            Sign In
          </TabButton>
          <TabButton
            active={activeTab === "signup"}
            onClick={() => setActiveTab("signup")}
          >
            Sign Up
          </TabButton>
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="px-8 pt-6 pb-8"
          >
            {activeTab === "signin" ? (
              <SignIn
                userPool={userPool}
                setUser={setUser}
                setIsGoogleAuthenticated={setIsGoogleAuthenticated}
              />
            ) : (
              <SignUp userPool={userPool} />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

function TabButton({ active, onClick, children }) {
  return (
    <button
      className={`w-1/2 py-3 text-center relative focus:outline-none transition-colors duration-300 ${
        active
          ? "text-blue-600 dark:text-blue-400"
          : "text-gray-500 dark:text-gray-400"
      }`}
      onClick={onClick}
    >
      {children}
      {active && (
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600 dark:bg-blue-400"
          layoutId="activeTab"
        />
      )}
    </button>
  );
}

export default AuthTabs;
