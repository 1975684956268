import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { getOAuthUrl, handleOAuthCallback } from "../graphql/mutations";

function GoogleAuthFlow({ setIsGoogleAuthenticated }) {
  const [authUrl, setAuthUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [authSuccess, setAuthSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [fetchAuthUrl] = useMutation(getOAuthUrl);
  const [processOAuthCallback] = useMutation(handleOAuthCallback);

  useEffect(() => {
    const getAuthUrl = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchAuthUrl();
        setAuthUrl(data.getOAuthUrl);
      } catch (err) {
        setError("Failed to fetch authorization URL: " + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    const handleOAuthCode = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get("code");
      if (code) {
        setIsLoading(true);
        setError("");
        try {
          const { data } = await processOAuthCallback({ variables: { code } });
          if (data.handleOAuthCallback === "REFRESH_TOKEN_SAVED") {
            localStorage.setItem("googleAuthPrompted", "true");
            setIsGoogleAuthenticated(true);
            setAuthSuccess(true);
            // Add a delay before redirecting
            setTimeout(() => {
              navigate("/dashboard");
            }, 3000);
          } else {
            throw new Error("Unexpected response from server");
          }
        } catch (err) {
          console.error("Error processing OAuth callback:", err);
          setError("Failed to authenticate with Google. Please try again.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (location.pathname === "/oauth/callback") {
      handleOAuthCode();
    } else {
      getAuthUrl();
    }
  }, [location, navigate, setIsGoogleAuthenticated]);

  const handleGoogleAuth = () => {
    window.location.href = authUrl;
  };

  const handleSkip = () => {
    localStorage.setItem("googleAuthPrompted", "true");
    setIsGoogleAuthenticated(false);
    navigate("/dashboard");
  };

  if (isLoading) {
    return (
      <div className="text-center py-10 dark:text-white">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  if (authSuccess) {
    return (
      <div className="text-center py-10 dark:text-white">
        <div className="animate-pulse text-green-500 text-5xl mb-4">✓</div>
        <p className="text-xl mb-2">Google Authentication Successful!</p>
        <p>Redirecting to dashboard...</p>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4 dark:text-white">
        Connect Your Google Account
      </h2>
      <p className="mb-4 dark:text-gray-300">
        Connecting your Google account allows you to send emails directly from
        our app. This step is optional but recommended for the best experience.
      </p>
      <div className="flex justify-between">
        <button
          onClick={handleGoogleAuth}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700"
        >
          Connect Google Account
        </button>
        <button
          onClick={handleSkip}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 dark:bg-gray-600 dark:text-gray-200 dark:hover:bg-gray-700"
        >
          Skip for Now
        </button>
      </div>
    </div>
  );
}

export default GoogleAuthFlow;
