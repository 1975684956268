import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { motion } from "framer-motion";
import { LayoutGrid, Table, Trash2 } from "lucide-react";
// GraphQL query to list contacts
export const LIST_CONTACTS = gql`
  query ListContacts {
    listContacts {
      id
      firstName
      lastName
      email
      phone
      contactSummary
      owner
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function ContactList() {
  const { loading, error, data, refetch } = useQuery(LIST_CONTACTS);
  const [contacts, setContacts] = useState([]);
  const [deleteContact] = useMutation(DELETE_CONTACT);
  const [viewMode, setViewMode] = useState("card");
  const [filterText, setFilterText] = useState("");
  const [contactToDelete, setContactToDelete] = useState(null);

  useEffect(() => {
    if (data && data.listContacts) {
      setContacts(data.listContacts);
    }
  }, [data]);

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/contact/${id}`);
  };

  const handleDeleteClick = (contact) => {
    setContactToDelete(contact);
  };

  const handleConfirmDelete = async () => {
    if (contactToDelete) {
      await handleDelete(contactToDelete.id);
      setContactToDelete(null);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteContact({ variables: { id } });
      refetch();
    } catch (err) {
      console.error("Error deleting contact:", err);
    }
  };

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
      contact.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
      contact.email.toLowerCase().includes(filterText.toLowerCase())
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error loading contacts: {error.message}</p>;

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
      <div className="max-w-3xl mx-auto mb-4">
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <input
            type="text"
            placeholder="Filter contacts..."
            className="p-2 rounded border w-full sm:w-64"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <div className="flex space-x-2">
            <button
              onClick={() => setViewMode("card")}
              className={`p-2 rounded ${
                viewMode === "card"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
              }`}
            >
              <LayoutGrid size={20} />
            </button>
            <button
              onClick={() => setViewMode("table")}
              className={`p-2 rounded ${
                viewMode === "table"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
              }`}
            >
              <Table size={20} />
            </button>
          </div>
        </div>
      </div>
      {filteredContacts.length === 0 ? (
        <p>No contacts found.</p>
      ) : viewMode === "card" ? (
        <CardView
          contacts={filteredContacts}
          onDeleteClick={handleDeleteClick}
        />
      ) : (
        <TableView
          contacts={filteredContacts}
          onDeleteClick={handleDeleteClick}
          onRowClick={handleRowClick}
        />
      )}
      <DeleteConfirmationModal
        isOpen={!!contactToDelete}
        onClose={() => setContactToDelete(null)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}

const CardView = ({ contacts, onDeleteClick }) => (
  <motion.div
    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
  >
    {contacts.map((contact) => (
      <motion.div key={contact.id} variants={itemVariants}>
        <ContactCard contact={contact} onDeleteClick={onDeleteClick} />
      </motion.div>
    ))}
  </motion.div>
);

const TableView = ({ contacts, onDeleteClick, onRowClick }) => (
  <div className="overflow-x-auto">
    <motion.div
      className="overflow-x-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <table className="min-w-full bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200 dark:bg-gray-700">
          <tr>
            <th className="py-2 px-4 text-left">Name</th>
            <th className="py-2 px-4 text-left">Email</th>
            <th className="py-2 px-4 text-left">Phone</th>
            <th className="py-2 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr
              key={contact.id}
              className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              onClick={() => onRowClick(contact.id)}
            >
              <td className="py-2 px-4">
                {contact.firstName} {contact.lastName}
              </td>
              <td className="py-2 px-4">{contact.email}</td>
              <td className="py-2 px-4">{contact.phone}</td>
              <td className="py-2 px-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteClick(contact);
                  }}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  </div>
);

const ContactCard = ({ contact, onDeleteClick }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer h-40 flex flex-col justify-between"
      onClick={() => navigate(`/contact/${contact.id}`)}
    >
      <div>
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">
          {contact.firstName} {contact.lastName}
        </h3>
        <p className="text-gray-600 dark:text-gray-400">{contact.email}</p>
        <p className="text-gray-600 dark:text-gray-400">{contact.phone}</p>
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClick(contact);
          }}
          className="text-red-500 hover:text-red-700"
        >
          <Trash2 size={20} />
        </button>
      </div>
    </div>
  );
};

export default ContactList;
