import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutPage from "./components/AboutPage";
import LegalPage from "./components/LegalPage";
import Pricing from "./components/Pricing";
import LandingPage from "./components/LandingPage";
import AuthTabs from "./components/AuthTabs";
import ConfirmSignUp from "./components/ConfirmSignUp";
import ContactList from "./components/ContactList";
import ContactDetails from "./components/ContactDetails";
import CreateContact from "./components/CreateContact";
import DraftEmails from "./components/DraftEmails";
import Touchpoints from "./components/Touchpoints";
import GoogleAuthFlow from "./components/GoogleAuthFlow";
import EnvironmentIndicator from "./components/EnvironmentIndicator";
import GoogleAuthChecker from "./components/GoogleAuthChecker";
import Settings from "./components/Settings";

import logo from "../src/assets/images/logo.png";
import awsConfig from "./awsConfig";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Cognito setup
const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.userPoolWebClientId,
});

// Apollo Client HTTP link to AppSync
const httpLink = createHttpLink({
  uri: awsConfig.graphqlEndpoint,
});

// Apollo Client auth link to add the JWT token to requests
const authLink = setContext(async (_, { headers }) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    const session = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) reject(err);
        else resolve(session);
      });
    });
    const token = session.getIdToken().getJwtToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return { headers };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    if (
      user &&
      !isGoogleAuthenticated &&
      !localStorage.getItem("googleAuthPrompted")
    ) {
      navigate("/google-auth");
    }
  }, [user, isGoogleAuthenticated, navigate]);

  function checkUser() {
    setIsLoading(true);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          setUser(null);
        } else if (session.isValid()) {
          setUser(cognitoUser);
        } else {
          setUser(null);
        }
        setIsLoading(false);
      });
    } else {
      setUser(null);
      setIsLoading(false);
    }
  }

  function handleSignOut() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setUser(null);
      setIsGoogleAuthenticated(false); // Add this line
      localStorage.removeItem("googleAuthPrompted"); // Add this line
      navigate("/");
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ApolloProvider client={client}>
      <div className="flex flex-col min-h-screen w-full bg-gray-100 dark:bg-gray-900 font-sans">
        <Header authProps={{ user, signOut: handleSignOut }} logo={logo} />
        <main className="flex-grow">
          {user && (
            <GoogleAuthChecker
              user={user}
              setIsGoogleAuthenticated={setIsGoogleAuthenticated}
            />
          )}
          <Routes>
            <Route
              path="/"
              element={
                user ? <Navigate to="/dashboard" replace /> : <LandingPage />
              }
            />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/legal" element={<LegalPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/auth"
              element={
                <AuthTabs
                  userPool={userPool}
                  setUser={setUser}
                  setIsGoogleAuthenticated={setIsGoogleAuthenticated}
                />
              }
            />
            <Route
              path="/confirm-signup"
              element={<ConfirmSignUp userPool={userPool} />}
            />
            <Route
              path="/dashboard"
              element={
                user ? <ContactList /> : <Navigate to="/signin" replace />
              }
            />
            <Route
              path="/create-contact"
              element={
                user ? <CreateContact /> : <Navigate to="/signin" replace />
              }
            />
            <Route
              path="/contact/:id"
              element={
                user ? <ContactDetails /> : <Navigate to="/signin" replace />
              }
            />
            <Route
              path="/drafts"
              element={
                user ? <DraftEmails /> : <Navigate to="/signin" replace />
              }
            />
            <Route
              path="/touchpoints"
              element={
                user ? <Touchpoints /> : <Navigate to="/signin" replace />
              }
            />
            <Route
              path="/google-auth"
              element={
                user ? (
                  <GoogleAuthFlow
                    setIsGoogleAuthenticated={setIsGoogleAuthenticated}
                  />
                ) : (
                  <Navigate to="/signin" replace />
                )
              }
            />
            <Route
              path="/oauth/callback"
              element={
                <GoogleAuthFlow
                  setIsGoogleAuthenticated={setIsGoogleAuthenticated}
                  isCallback={true}
                />
              }
            />
            <Route
              path="/settings"
              element={
                user ? (
                  <Settings
                    user={user}
                    setIsGoogleAuthenticated={setIsGoogleAuthenticated}
                  />
                ) : (
                  <Navigate to="/signin" replace />
                )
              }
            />
          </Routes>
        </main>
        <Footer />
        <EnvironmentIndicator />
      </div>
    </ApolloProvider>
  );
}

export default App;
