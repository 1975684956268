import { UserPlus, Radio, MessageSquareText } from "lucide-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const LandingPage = () => {
  return (
    <motion.div
      className="bg-gray-100 dark:bg-gray-900 min-h-screen"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container mx-auto px-4 py-8">
        <motion.header className="text-center mb-12" variants={itemVariants}>
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">
            Welcome to rēlii
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Harness the power of AI to nurture and grow your personal
            connections.
          </p>
          <p className="text-lg text-gray-700 dark:text-gray-300 mt-2">
            rēlii is more than just a tool—it's your partner in building
            meaningful relationships.
          </p>
        </motion.header>

        <main>
          <motion.section
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12"
            variants={itemVariants}
          >
            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
              variants={itemVariants}
            >
              <div className="flex justify-center mb-4">
                <UserPlus size={48} className="text-green-500" />
              </div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Seamless Onboarding
              </h2>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed text-m text-center">
                Effortlessly add new connections to your network and capture all
                essential information with ease. Our{" "}
                <strong className="text-green-600 dark:text-green-400">
                  intelligent onboarding process
                </strong>{" "}
                sets the stage for meaningful relationships, leveraging your{" "}
                <strong className="text-green-600 dark:text-green-400">
                  existing contacts and tools
                </strong>
                .
              </p>
              <div className="text-center mt-4">
                <Link
                  to="/about"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  Learn more about our onboarding capabilities
                </Link>
              </div>
            </motion.div>

            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
              variants={itemVariants}
            >
              <div className="flex justify-center mb-4">
                <Radio size={48} className="text-blue-500" />
              </div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Stay Connected
              </h2>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed text-m text-center">
                Keep in sync with your network by identifying the{" "}
                <strong className="text-blue-600 dark:text-blue-400">
                  perfect moments to reach out and engage
                </strong>
                . Maintain meaningful connections with{" "}
                <strong className="text-blue-600 dark:text-blue-400">
                  timely prompts and insights
                </strong>{" "}
                tailored to your relationships, but remember, the more you
                engage, the more you gain.
              </p>

              <div className="text-center mt-4">
                <Link
                  to="/about"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  Discover how we enhance your connections
                </Link>
              </div>
            </motion.div>

            <motion.div
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
              variants={itemVariants}
            >
              <div className="flex justify-center mb-4">
                <MessageSquareText size={48} className="text-purple-500" />
              </div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
                Engage Effectively
              </h2>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed text-m text-center">
                Initiate engaging conversations with{" "}
                <strong className="text-purple-600 dark:text-purple-400">
                  personalized, context-aware message drafts
                </strong>
                . Whether it's a quick check-in or a significant update, our AI
                shows you the path, but it's your{" "}
                <strong className="text-purple-600 dark:text-purple-400">
                  active participation that makes the difference
                </strong>
                .
              </p>
              <div className="text-center mt-4">
                <Link
                  to="/about"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  See how we empower your communications
                </Link>
              </div>
            </motion.div>
          </motion.section>

          <motion.div className="text-center" variants={itemVariants}>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              Ready to dive deeper?{" "}
              <Link
                to="/about"
                className="text-blue-600 dark:text-blue-400 hover:underline"
              >
                Learn more about rēlii and our mission
              </Link>
              .
            </p>
          </motion.div>
        </main>
      </div>
    </motion.div>
  );
};

export default LandingPage;
