import React from "react";
import { Book, Shield, Copyright } from "lucide-react";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const LegalPage = () => {
  return (
    <motion.div
      className="bg-gray-100 dark:bg-gray-900 min-h-screen"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container mx-auto px-4 py-8">
        <motion.header className="text-center mb-12" variants={itemVariants}>
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">
            Legal Information
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Important legal details about rēlii's services and policies
          </p>
        </motion.header>

        <main className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Book className="text-blue-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Terms of Service
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              Our terms of service will be provided here. This section will
              outline the rules and guidelines for using rēlii's services.
            </p>
          </motion.section>

          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Shield className="text-green-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Privacy Policy
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              Our privacy policy will be provided here. This section will detail
              how we collect, use, and protect your personal information.
            </p>
          </motion.section>

          <motion.section
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md md:col-span-2"
            variants={itemVariants}
          >
            <div className="flex items-center mb-4">
              <Copyright className="text-purple-500 mr-2" size={24} />
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Copyright Notice
              </h2>
            </div>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              Copyright information will be provided here. This section will
              cover the intellectual property rights associated with rēlii and
              its content.
            </p>
          </motion.section>
        </main>
      </div>
    </motion.div>
  );
};

export default LegalPage;
