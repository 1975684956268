import React from "react";

const EnvironmentIndicator = () => {
  const env = process.env.REACT_APP_ENV || "dev";
  const isDev = env !== "prod";

  if (!isDev) return null;

  return (
    <div className="fixed bottom-0 right-0 m-4 px-2 py-1 text-sm font-bold rounded bg-green-500 text-white">
      DEVELOPMENT
    </div>
  );
};

export default EnvironmentIndicator;
