import { gql } from "@apollo/client";

export const getContact = gql`
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      fullName
      email
      phone
      contactSummary
      birthday {
        month
        day
      }
      lastInteractionDate
      reconnectInterval
      location {
        street1
        street2
        city
        stateOrProvince
        postalCode
        country
      }
      touchpoints {
        event
        monthDay {
          month
          day
        }
        nextTouchDate
        nextTouchReminderDate
        recurrence
        reminderInDays
        status
        type
      }
      data
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fullName
        email
        phone
        lastInteractionDate
        reconnectInterval
        locationCity
        locationStateOrProvince
        locationCountry
        data
        currentVersion
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const CHECK_GOOGLE_AUTH_STATUS = gql`
  query CheckGoogleAuthStatus($email: String!) {
    checkGoogleAuthStatus(email: $email)
  }
`;
