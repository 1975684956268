import { useState } from "react";
import { useMutation } from "@apollo/client";
import { contactCreateText } from "../graphql/mutations";
import { LIST_CONTACTS } from "../components/ContactList";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const CreateContact = () => {
  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const examples = [
    "Met Jane Doe, VP at TechCorp, phone: 123-456-7890, email: jane@techcorp.com. She has two kids, Max and Lily, and enjoys hiking and painting.",
    "John, CTO at InnovateX, phone: 987-654-3210. John is a fan of the New York Giants (NFL) and the New York Knicks (NBA). He recently completed a triathlon.",
    "Lily Evans lives at 123 Main St, Apt 4B, Portland, OR 97201, USA. She is a Content Creator at YouTube since 2021. Previously, she lived in San Francisco, CA from 2017 to 2021 and in Seattle, WA from 2015 to 2017. Lily enjoys yoga and cooking. Her birthday is on February 18. She has a partner named John and they have a cat named Luna. She is a fan of the Portland Trail Blazers. Lily graduated from Oregon State University with a Bachelor's degree in Communications in 2016. She speaks Spanish and English. Her favorite charity is Feeding America.",
  ];

  // Apollo mutation hook
  const [createContact] = useMutation(contactCreateText, {
    onCompleted: (data) => {
      navigate("/");
    },
    onError: (err) => {
      setError(`An error occurred: ${err.message}`);
    },
    update: (cache, { data: { contactCreateText } }) => {
      const newContact = JSON.parse(contactCreateText.processedText);
      const existingContacts = cache.readQuery({ query: LIST_CONTACTS });

      if (existingContacts && existingContacts.listContacts) {
        cache.writeQuery({
          query: LIST_CONTACTS,
          data: {
            listContacts: [...existingContacts.listContacts, newContact],
          },
        });
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await createContact({
        variables: { text: textInput },
      });

      if (
        !response.data?.contactCreateText ||
        !response.data.contactCreateText.processedText
      ) {
        console.error("Invalid response structure:", response);
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error creating contact:", error);
      setError(error.message || "Unknown error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleExampleClick = (example) => {
    setTextInput(example);
  };

  return (
    <motion.div
      className="max-w-2xl mx-auto mt-8 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg shadow-xl overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="p-8">
        <h2 className="text-3xl font-extrabold mb-6 text-white text-center">
          Add Your New Contact
        </h2>
        <p className="text-center text-white mb-8">
          Capture the details that matter, in any way you like.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <textarea
              id="textInput"
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              className="w-full px-4 py-3 border rounded-md h-64 text-gray-700 bg-white focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
              placeholder="Enter free text information about your contact here..."
            />
          </div>
          {error && <p className="text-red-300 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-white text-blue-600 py-3 px-5 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-all duration-200"
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Create Contact"}
          </button>
        </form>
      </div>
      <div className="bg-white dark:bg-gray-800 p-8">
        <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
          Example Inputs:
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {examples.map((example, index) => (
            <div
              key={index}
              className={`bg-gray-100 dark:bg-gray-700 p-4 rounded-md shadow-sm hover:shadow-lg transition-shadow duration-200 cursor-pointer ${
                index === 2 ? "col-span-2 text-lg leading-relaxed" : ""
              }`}
              onClick={() => handleExampleClick(example)}
            >
              <p className="text-gray-800 dark:text-white font-medium">
                {example}
              </p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CreateContact;
