import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { motion } from "framer-motion";

const LIST_DRAFT_EMAILS = gql`
  query ListDraftEmails {
    listDraftEmails {
      contactId
      touchpointEvent
      draftEmail
      createdAt
      status
    }
  }
`;

const TRIGGER_DRAFT_EMAILS_HANDLER = gql`
  mutation TriggerDraftEmailsHandler {
    createDraftEmail(
      input: {
        contactId: "dummy"
        touchpointEvent: "trigger"
        draftEmail: "testing testing"
        status: "pending"
      }
    ) {
      contactId
      createdAt
    }
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function DraftEmails() {
  const { loading, error, data, refetch } = useQuery(LIST_DRAFT_EMAILS);
  const [triggerDraftEmailsHandler] = useMutation(TRIGGER_DRAFT_EMAILS_HANDLER);

  const handleTriggerDraftEmailsHandler = async () => {
    try {
      await triggerDraftEmailsHandler();
      alert("Draft emails handler triggered successfully!");
      refetch();
    } catch (error) {
      console.error("Error triggering draft emails handler:", error);
      alert(
        "Error triggering draft emails handler. Check console for details."
      );
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const draftEmails = data.listDraftEmails || [];

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 dark:text-white text-center">
        Draft Emails
      </h2>
      <button
        onClick={handleTriggerDraftEmailsHandler}
        className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Trigger Draft Emails Handler
      </button>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {draftEmails.map((email) => (
          <motion.div
            key={`${email.contactId}-${email.touchpointEvent}`}
            variants={itemVariants}
            className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md"
          >
            <h3 className="text-xl font-semibold mb-2">
              {email.touchpointEvent}
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-2">
              {email.draftEmail}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-300">
              Status: {email.status}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default DraftEmails;
