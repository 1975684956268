import { gql } from "@apollo/client";

export const contactCreateText = gql`
  mutation ContactCreateText($text: String!) {
    contactCreateText(text: $text) {
      processedText
    }
  }
`;

export const getOAuthUrl = gql`
  mutation GetOAuthUrl {
    getOAuthUrl
  }
`;

export const handleOAuthCallback = gql`
  mutation HandleOAuthCallback($code: String!) {
    handleOAuthCallback(code: $code)
  }
`;

export const sendEmail = gql`
  mutation SendEmail($to: String!, $subject: String!, $body: String!) {
    sendEmail(to: $to, subject: $subject, body: $body)
  }
`;
