import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Phone,
  Mail,
  Calendar,
  User,
  Briefcase,
  Heart,
  Users,
  PawPrint,
} from "lucide-react";
import { getContact } from "../graphql/queries";
import { motion, AnimatePresence } from "framer-motion";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";

const tabs = [
  { name: "Summary", id: "summary" },
  { name: "Details", id: "details" },
  { name: "Professional", id: "professional" },
  { name: "Touchpoints", id: "touchpoints" },
  { name: "Interaction History", id: "interactionHistory" },
];

const tabVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

const ContactDetails = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(getContact, {
    variables: { id },
  });
  const [contact, setContact] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");

  useEffect(() => {
    if (data && data.getContact) {
      const contactData = data.getContact;
      let parsedData = contactData.data ? JSON.parse(contactData.data) : {};
      setContact({ ...contactData, parsedData });
    }
  }, [data]);

  if (loading)
    return (
      <p className="text-gray-500 dark:text-gray-300">
        Loading contact details...
      </p>
    );
  if (error)
    return (
      <p className="text-red-500">
        Error loading contact details: {error.message}
      </p>
    );
  if (!contact)
    return (
      <p className="text-gray-500 dark:text-gray-300">
        No contact details found.
      </p>
    );

  const { fullName, email, phone, contactSummary, parsedData } = contact;

  const renderTabContent = () => {
    switch (activeTab) {
      case "summary":
        return (
          <div className="space-y-4 text-black dark:text-white">
            {contactSummary ? (
              <p className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                {contactSummary}
              </p>
            ) : (
              <p className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                No summary available
              </p>
            )}
          </div>
        );
      case "details":
        return (
          <div className="space-y-4 text-black dark:text-white">
            {parsedData.family?.spouse && (
              <div className="flex items-center bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                <Heart size={20} className="mr-2 text-red-500" />
                <p>Spouse: {parsedData.family.spouse}</p>
              </div>
            )}
            {parsedData.family?.children?.length > 0 && (
              <div className="flex items-center bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                <Users size={20} className="mr-2 text-blue-500" />
                <p>Children: {parsedData.family.children.join(", ")}</p>
              </div>
            )}
            {parsedData.pets?.length > 0 && (
              <div className="flex items-center bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                <PawPrint size={20} className="mr-2 text-green-500" />
                <p>
                  Pets:{" "}
                  {parsedData.pets
                    .map((pet) => `${pet.name} (${pet.type})`)
                    .join(", ")}
                </p>
              </div>
            )}
            {/* Add similar styling for other details */}
          </div>
        );
      case "professional":
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {parsedData.jobs?.map((job, index) => (
              <div
                key={index}
                className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg flex"
              >
                <Briefcase
                  size={24}
                  className="mr-4 text-indigo-500 flex-shrink-0"
                />
                <div>
                  <h3 className="text-lg font-semibold">{job.jobTitle}</h3>
                  <p>{job.company}</p>
                  <p>
                    {job.startYear} - {job.endYear ? job.endYear : "Present"}
                  </p>
                  <p>Industry: {job.industry}</p>
                </div>
              </div>
            ))}
          </div>
        );
      case "touchpoints":
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {contact.touchpoints?.map((tp, index) => (
              <div
                key={index}
                className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg flex"
              >
                <Calendar
                  size={24}
                  className="mr-4 text-blue-500 flex-shrink-0"
                />
                <div>
                  <h3 className="text-lg font-semibold">{tp.event}</h3>
                  <p className="text-sm">Type: {tp.type}</p>
                  <p className="text-sm">
                    Date:{" "}
                    {tp.monthDay
                      ? `${tp.monthDay.month}/${tp.monthDay.day}`
                      : "Not specified"}
                  </p>
                  <p className="text-sm">
                    Next Touch: {tp.nextTouchDate || "Not specified"}
                  </p>
                  <p className="text-sm">Recurrence: {tp.recurrence}</p>
                </div>
              </div>
            ))}
          </div>
        );
      case "interactionHistory":
        return (
          <Timeline position="alternate">
            {contact.parsedData.interactionHistory?.map(
              (interaction, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="secondary">
                      <User size={16} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold">
                        {interaction.type}
                      </h3>
                      <p>Date: {interaction.date}</p>
                      <p>Description: {interaction.description}</p>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              )
            )}
          </Timeline>
        );
      default:
        return <div>No content available</div>;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto mt-8 p-8 bg-white dark:bg-gray-900 rounded-lg shadow-md"
    >
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 dark:text-white text-center">
        {fullName}
      </h2>
      <div className="flex justify-center space-x-4 mb-8">
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          href={`mailto:${email}`}
          className="text-indigo-500 dark:text-indigo-300"
        >
          <Mail size={20} />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          href={`tel:${phone}`}
          className="text-indigo-500 dark:text-indigo-300"
        >
          <Phone size={20} />
        </motion.a>
      </div>
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
        <nav className="flex flex-wrap justify-center space-x-2 space-y-2">
          {tabs.map((tab) => (
            <motion.button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`py-2 px-4 rounded-t-lg font-semibold ${
                activeTab === tab.id
                  ? "bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white"
                  : "text-gray-600 dark:text-gray-300"
              }`}
              whileHover={{ backgroundColor: "rgba(107, 114, 128, 0.1)" }}
              whileTap={{ scale: 0.95 }}
            >
              {tab.name}
            </motion.button>
          ))}
        </nav>
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          variants={tabVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="p-4"
        >
          {renderTabContent()}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default ContactDetails;
