import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AddContactButton from "./AddContactButton";

function Header({ authProps, logo }) {
  const { user, signOut } = authProps;

  return (
    <header className="bg-gradient-to-r from-blue-600 to-purple-600 p-4 shadow-md">
      <nav className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 w-auto mr-2" />
          <span className="text-white text-2xl font-bold">rēlii</span>
        </Link>
        <div className="flex items-center space-x-6">
          <Link
            to="/about"
            className="text-white hover:text-gray-200 transition-colors"
          >
            About
          </Link>
          {user ? (
            <>
              <Link
                to="/"
                className="text-white hover:text-gray-200 transition-colors"
              >
                Contacts
              </Link>
              <Link
                to="/touchpoints"
                className="text-white hover:text-gray-200 transition-colors"
              >
                Touchpoints
              </Link>
              <Link
                to="/settings"
                className="text-white hover:text-gray-200 transition-colors"
              >
                Settings
              </Link>
              <AddContactButton isCompact={true} />
              <button
                onClick={signOut}
                className="bg-white text-blue-600 px-4 py-2 rounded-md hover:bg-gray-100 transition-colors"
              >
                Sign Out
              </button>
            </>
          ) : (
            <Link
              to="/auth"
              className="bg-white text-blue-600 px-4 py-2 rounded-md hover:bg-gray-100 transition-colors"
            >
              Login / Register
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  authProps: PropTypes.shape({
    user: PropTypes.object,
    signOut: PropTypes.func,
  }),
  logo: PropTypes.string.isRequired,
};

export default Header;
