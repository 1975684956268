import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { CHECK_GOOGLE_AUTH_STATUS } from "../graphql/queries";
import LoadingSpinner from "./LoadingSpinner";
import GoogleAuthFlow from "./GoogleAuthFlow";

function Settings({ user, setIsGoogleAuthenticated }) {
  const [email, setEmail] = useState("");
  const [showGoogleAuthFlow, setShowGoogleAuthFlow] = useState(false);

  const { loading, error, data, refetch } = useQuery(CHECK_GOOGLE_AUTH_STATUS, {
    variables: { email: user.getUsername() },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    user.getUserAttributes((err, attributes) => {
      if (err) {
        console.error("Error getting user attributes:", err);
        return;
      }
      const emailAttribute = attributes.find((attr) => attr.Name === "email");
      if (emailAttribute) {
        setEmail(emailAttribute.Value);
      }
    });
  }, [user]);

  useEffect(() => {
    if (data && data.checkGoogleAuthStatus) {
      setIsGoogleAuthenticated(data.checkGoogleAuthStatus);
    }
  }, [data, setIsGoogleAuthenticated]);

  const handleGoogleAuth = () => {
    setShowGoogleAuthFlow(true);
  };

  if (loading) return <LoadingSpinner />;
  if (error)
    return (
      <p className="text-red-500 dark:text-red-400">Error: {error.message}</p>
    );

  const isGoogleConnected = data?.checkGoogleAuthStatus || false;

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold mb-6 text-gray-800 dark:text-white text-center">
          Settings
        </h1>
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6">
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            <strong>Email:</strong> {email}
          </p>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            <strong>Google Auth Status:</strong>{" "}
            {isGoogleConnected ? "Connected" : "Not Connected"}
          </p>
          {!showGoogleAuthFlow ? (
            <button
              onClick={handleGoogleAuth}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200"
            >
              {isGoogleConnected
                ? "Reconnect Google Account"
                : "Connect with Google"}
            </button>
          ) : (
            <GoogleAuthFlow
              setIsGoogleAuthenticated={setIsGoogleAuthenticated}
              onComplete={() => {
                setShowGoogleAuthFlow(false);
                refetch();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
